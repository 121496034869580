import React from "react";
import ContentSection from "components/common/content-section";
import TextTitle from "components/common/text-title";
import { Row, Col } from "reactstrap";
import CardItem from "./components/card-item";
import "./features.scss";
import { Link } from "react-router-dom";

const Features = () => {
  return (
    <ContentSection bgClass="py-5 d-flex flex-row justify-content-center features">
      <div className="w-100 text-center">
        <div>
          <div className="w-100 text-center px-5 mt-5">
            <TextTitle>
              <div className="title text-uppercase text-center">
                <h2>Traffic Management Services Victoria</h2>
              </div>
            </TextTitle>
          </div>
          <p className="mt-5 p-details">
            Swift Traffic Pty Ltd provides Traffic Management and Traffic
            Planning services in Melbourne as well as other areas in Victoria.
            We have qualified and experienced Traffic Controllers ready to
            exceed your expectations.
          </p>
        </div>
      </div>

      <Row className="mt-5 px-2 cards">
        <Col lg="6" md="6" xs="12" className="mb-4 item">
          <CardItem>
            <img src="/images/roadworks-2.jpg" className="shadow" alt="" />
            <div className="title">
              <h3>Traffic Management</h3>
            </div>

            <p className="p-details">
              Swift Traffic offers round-the-clock Traffic Management services
              throughout Victoria. Our team consists of highly trained, fully
              accredited traffic controllers, with fully equipped vehicles,
              ready for any situation on any type of road.
            </p>
            <div>
              <Link to="/services/" className="sw-btn-primary btn">
                Learn More
              </Link>
            </div>
          </CardItem>
        </Col>
        <Col lg="6" md="6" xs="12" className="mb-4 item">
          <CardItem>
            <img src="/images/roadworks-5.jpg" className="shadow" alt="" />

            <div className="title">
              <h3>Traffic Planning</h3>
            </div>

            <p className="p-details">
              At Swift Traffic, we believe that exceptional road execution
              begins with a superior traffic plan. We offer the highest quality
              Traffic Guidance Schemes, meticulously designed to align with your
              specific needs and regulations.
            </p>
            <div>
              <Link to="/services/" className="sw-btn-primary btn">
                Learn More
              </Link>
            </div>
          </CardItem>
        </Col>
      </Row>
      {/* <div className="w-100 text-center px-5 mt-5">
        <TextTitle>
          <div className="title px-5 mt-5 text-uppercase">
            <h2>Safety always comes first at Swift Traffic.</h2>
          </div>
        </TextTitle>
      </div>
      <p className="mt-5 p-details text-center px-5">
        XX
      </p>
      <p className="p-details text-center px-5">
        <strong>Please slow down when you are driving past road works.</strong>
      </p>
      <div
        className="mt-5"
        style={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}
      >
        <div className="video-wrapper">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/1YVM4oB84VM?si=Xtxf354XP76He7gb"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div> */}
    </ContentSection>
  );
};

export default Features;
